.startOverview {
}

.overviewCard{



  background: white;
  margin: 0 0 25px;
  border-radius: 6px;
  overflow: hidden;

  &.first{
    margin-top: 25px;
  }
  .overviewCardText{
    padding: 20px;
    font-size: 12px;

    p, a{
      font-size: 12px;
    }

    a{
      color: white;
      font-weight: bold;
    }

    .cardTextTitle{
      font-size: 14px;
    }
  }

  &.background-brand {
    background-color: $color-brand;
    color: white;
  }

  &.background-darkgrey {
    background-color: $color-darkgrey;
    color: white;
    .overviewCardText{
      .cardTextTitle{
        color: $color-grey;
        margin-bottom: 4px;
      }
    }

  }




  .overviewCardFigure{
      margin: 0;
      height: 181px;
    background-color: #1b1e21;
  }


  .overviewCardFooter{
    position: relative;
    padding-right: 55px;
    .overviewCardTitle{
      padding: 20px;

      font-size: 14px;

      h3 {
        margin: 0;
        color: black;
        font-size: 24px;
        margin-top: -6px;
      }

    }

    .overviewGoToButton{
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      height: 35px;
      width: 35px;
      display: block;
      background-image: url("../../img/svg/btn_weiter.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

  }

  .overviewCardWrap {
    position: relative;
    z-index: 2;
  }

 .overviewCardShadow{
   position: absolute;
    z-index: -1;
    content: ' ';
    display: block;
    width: auto;
    left: 40px;
    right: 40px;
    height: 1px;
    margin: 0 auto;
    box-shadow: 0 3px 12px 0 rgba(black, 0.8);
    border-radius: 25px;
  }

}