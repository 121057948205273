/************************************************/
/*  Vendor Variables
/************************************************/

$grid-gutter-width: 50px;
$plyr-color-gunmetal: #C0B8AB;
$plyr-video-control-bg-hover: darken(#C0B8AB, 10%);

/************************************************/
/*  Fonts
/************************************************/

$fonts: "../../fonts";

@font-face {
  font-family: 'Eurostile';
  src: url('#{$fonts}/Eurostile/EurostileBold.eot');
  src: url('#{$fonts}/Eurostile/EurostileBold.eot?#iefix') format('embedded-opentype'),
  url('#{$fonts}/Eurostile/EurostileBold.woff2') format('woff2'),
  url('#{$fonts}/Eurostile/EurostileBold.woff') format('woff'),
  url('#{$fonts}/Eurostile/EurostileBold.ttf') format('truetype'),
  url('#{$fonts}/Eurostile/EurostileBold.svg#EurostileBold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Eurostile';
  src: url('#{$fonts}/Eurostile/EurostileRegular.eot');
  src: url('#{$fonts}/Eurostile/EurostileRegular.eot?#iefix') format('embedded-opentype'),
  url('#{$fonts}/Eurostile/EurostileRegular.woff2') format('woff2'),
  url('#{$fonts}/Eurostile/EurostileRegular.woff') format('woff'),
  url('#{$fonts}/Eurostile/EurostileRegular.ttf') format('truetype'),
  url('#{$fonts}/Eurostile/EurostileRegular.svg#EurostileRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('#{$fonts}/Playfair/PlayfairDisplay-Italic.eot');
  src: local('Playfair Display Italic'), local('PlayfairDisplay-Italic'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Italic.eot?#iefix') format('embedded-opentype'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Italic.woff2') format('woff2'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Italic.woff') format('woff'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Italic.ttf') format('truetype'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Italic.svg#PlayfairDisplay-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('#{$fonts}/Playfair/PlayfairDisplay-BoldItalic.eot');
  src: local('Playfair Display Bold Italic'), local('PlayfairDisplay-BoldItalic'),
  url('#{$fonts}/Playfair/PlayfairDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$fonts}/Playfair/PlayfairDisplay-BoldItalic.woff2') format('woff2'),
  url('#{$fonts}/Playfair/PlayfairDisplay-BoldItalic.woff') format('woff'),
  url('#{$fonts}/Playfair/PlayfairDisplay-BoldItalic.ttf') format('truetype'),
  url('#{$fonts}/Playfair/PlayfairDisplay-BoldItalic.svg#PlayfairDisplay-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('#{$fonts}/Playfair/PlayfairDisplay-Regular.eot');
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Regular.woff2') format('woff2'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Regular.woff') format('woff'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Regular.ttf') format('truetype'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Regular.svg#PlayfairDisplay-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('#{$fonts}/Playfair/PlayfairDisplay-Bold.eot');
  src: local('Playfair Display Bold'), local('PlayfairDisplay-Bold'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Bold.woff2') format('woff2'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Bold.woff') format('woff'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Bold.ttf') format('truetype'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Bold.svg#PlayfairDisplay-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('#{$fonts}/Playfair/PlayfairDisplay-BlackItalic.eot');
  src: local('Playfair Display Black Italic'), local('PlayfairDisplay-BlackItalic'),
  url('#{$fonts}/Playfair/PlayfairDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$fonts}/Playfair/PlayfairDisplay-BlackItalic.woff2') format('woff2'),
  url('#{$fonts}/Playfair/PlayfairDisplay-BlackItalic.woff') format('woff'),
  url('#{$fonts}/Playfair/PlayfairDisplay-BlackItalic.ttf') format('truetype'),
  url('#{$fonts}/Playfair/PlayfairDisplay-BlackItalic.svg#PlayfairDisplay-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('#{$fonts}/Playfair/PlayfairDisplay-Black.eot');
  src: local('Playfair Display Black'), local('PlayfairDisplay-Black'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Black.eot?#iefix') format('embedded-opentype'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Black.woff2') format('woff2'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Black.woff') format('woff'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Black.ttf') format('truetype'),
  url('#{$fonts}/Playfair/PlayfairDisplay-Black.svg#PlayfairDisplay-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

/************************************************/
/*  Vendors
/************************************************/

@import "node_modules/bootstrap/scss/bootstrap";
@import "web/src/vendors/plyr-master/src/sass/plyr";

/************************************************/
/*  MAIN STYLES
/************************************************/

@import "_variables.scss";
@import "_components.scss";
@import "_modules.scss";
