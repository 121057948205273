body {
  font-family: 'Eurostile', sans-serif;
  height: 100vh;
  background-color: $color-darkgrey;
  padding: $grid-gutter-width/2;
}

p{
  font-size: 0.875rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  font-weight: bold;
  color: $color-brand
}

h1 {
  font-size: 2.2rem;
  margin-bottom: 5px;
  .smallH1{
    font-size: 1rem;
  }
}

h2 {
  font-size: 1.2rem;
  color: $color-grey;
}
.headline,
.headlineEn{
  text-transform: uppercase;
}

.background-brand {
  background-color: $color-brand;
  color: white;
}

.overlayVideo {
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 1;
  position: absolute;
}

.videoWrap {
  max-width: 100%;
  margin: $grid-gutter-width/3 0;
  position: relative;
  h4 {
    color: #c0b8ab;
    z-index: 100;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
  }

  .uhrenbezeichnung6Pictures {
    font-size: 1.3125rem;
    top: 68%;
  }

  .uhrenbezeichnungBigVideo {
    top: 57%;
  }

  .automatenuhrenUhrenbezeichnung {
    top: 64%;
  }
  .uhrenbezeichnungBeha {
    top: 64%;
  }
}

.pictureWrap {
  position: relative;
  margin: 16.66667px 0;
  img {
    max-width: 100%;
  }
  h4 {
    color: #c0b8ab;
    z-index: 100;
  }
  .uhrenbezeichnungSmallPic {
    top: 50%;
    transform: translate(-50%,-50%);
    left: 50%;
    position: absolute;
  }
  .uhrenbezeichnungBigPic {
    top: 50%;
    transform: translate(-50%,-50%);
    left: 50%;
    position: absolute;
  }
}

.overlayPicture{
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 1;
  position: absolute;
}

.smallerPaddingContainer {
  padding-right: 32px;
  padding-left: 32px;
}

.hide{
  display:none;
}