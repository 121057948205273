.index{
  background-image: url("../../img/Splashscreen/hintergrund_video.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;


  .splashPageContent{
    position: fixed;
    left: 25px;
    right: 25px;
    bottom: 25px;
    h1 {
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 1.5rem;
    }

    .btn{
      margin-bottom: 50px;
    }

  }

}