.btn {
  width: 100%;
  border-radius: 6px;
  background-color: $color-brand;

  color: white;
  padding: 12px 0;

  &:hover {
    color: white;
  }

  &:focus, &:active{
    outline: 0;
  }

}


.btnRound{
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background-color: $color-brand;
}

.plyr--video .plyr__controls .plyr__control.plyr__tab-focus, .plyr--video .plyr__controls .plyr__control:hover, .plyr--video .plyr__controls .plyr__control[aria-expanded=true]{
  background: $color-brand;
}

.plyr--video .plyr__controls {
  padding: 0;
}

.plyr__control--overlaid {
  background: none;

  svg{
    width: 40px;
    height: 40px;
  }
}

.col-4 {
  .plyr__control--overlaid {

    svg{
      width: 30px;
      height: 30px;
    }
  }
}

.bigVideo{
  button.plyr__control {
    display: none;
  }
}

.plyr__control--overlaid {
  padding: 64px 127px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.plyr__control--overlaid:hover {
  background: none;
}