// --------------------------------------------------------------
// Hiding content nicely
// --------------------------------------------------------------

// Screen reader only elements
.plyr__sr-only {
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;

    // !important is not always needed
    @if $plyr-sr-only-important {
        border: 0 !important;
        height: 1px !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
    } @else {
        border: 0;
        height: 1px;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}
